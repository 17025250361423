import {Navigate, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {setGithubToken} from "../../firebase";
import {useHttp} from "../../hooks/use-http";


export default function GithubCode() {
  const [isTokenSet, setIsTokenSet] = useState(false);
  const code = new URLSearchParams(window.location.search).get("code");
  const {request, loading} = useHttp();

  const handleGithubCode = async () => {
    try {
      let res = await request(`/github/authenticate?github_code=${code}`, 'POST');
      setIsTokenSet(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (code) {
      handleGithubCode();
    }
  }, [code]);

  if (!code || isTokenSet) {
    return <Navigate to={"/dashboard"} />;
  }

  return null;
}