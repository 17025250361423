import { createContext, useEffect, useReducer } from "react";
import { MatxLoading } from "app/components";
import auth from "../firebase";

const initialState = {
  user: null,
  isAuthenticated: false,
  isInitialized: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, user, isInitialized: true };
    }
    case "LOGIN": {
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }
    case "LOGOUT": {
      return { ...state, isAuthenticated: false, user: null };
    }
    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  login: () => {},
  logout: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (user) => {
    try {
      dispatch({ type: "LOGIN", payload: { user } });
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const logout = async () => {
    await auth.signOut();
    dispatch({ type: "LOGOUT" });
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch({ type: "INIT", payload: { isAuthenticated: true, user } });
      } else {
        dispatch({ type: "INIT", payload: { isAuthenticated: false, user: null } });
      }
    });

    return () => unsubscribe();
  }, []);

  if (!state.isInitialized) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;