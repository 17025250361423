export const PROGRAMMING_LANGUAGES_ICONS = {
  'git': '/assets/images/logos/github-mark.svg',
  'c': '/assets/images/programming_languages/c.svg',
  'c++': '/assets/images/programming_languages/cpp.svg',
  'c#': '/assets/images/programming_languages/csharp.svg',
  'css': '/assets/images/programming_languages/css.svg',
  'dart': '/assets/images/programming_languages/dart.svg',
  'go': '/assets/images/programming_languages/go.svg',
  'haskell': '/assets/images/programming_languages/haskell.svg',
  'html': '/assets/images/programming_languages/html.svg',
  'java': '/assets/images/programming_languages/java.svg',
  'javascript': '/assets/images/programming_languages/javascript.svg',
  'kotlin': '/assets/images/programming_languages/kotlin.svg',
  'lua': '/assets/images/programming_languages/lua.svg',
  'php': '/assets/images/programming_languages/php.svg',
  'python': '/assets/images/programming_languages/python.svg',
  'r': '/assets/images/programming_languages/r.svg',
  'ruby': '/assets/images/programming_languages/ruby.svg',
  'rust': '/assets/images/programming_languages/rust.svg',
  'swift': '/assets/images/programming_languages/swift.svg',
  'typescript': '/assets/images/programming_languages/typescript.svg',
};

export const PACKAGE_MANAGERS_LOGOS = {
  'npm': '/assets/images/logos/npm.svg',
  'yarn': '/assets/images/logos/yarn.svg',
  'pip': '/assets/images/logos/pip.svg',
  'go': '/assets/images/logos/go.svg',
};

export const PACKAGE_MANAGERS_LINKS = {
  'npm': 'https://www.npmjs.com/',
  'yarn': 'https://yarnpkg.com/',
  'pip': 'https://pypi.org/',
  'go': 'https://pkg.go.dev/',
};

export const PROGRAMMING_LANGUAGES = [
  'git',
  'c',
  'c++',
  'c#',
  'css',
  'dart',
  'go',
  'haskell',
  'html',
  'java',
  'javascript',
  'kotlin',
  'lua',
  'php',
  'python',
  'r',
  'ruby',
  'rust',
  'swift',
  'typescript',
];

const TEST_STRIPE_SUBSCRIPTIONS = [
  {
    id: "price_1Pv8lSJwMgFSZnqLNp020rcf",
    features: ["Unique sticker collection"],
    name: "Contributor",
    price: 500
  },
  {
    id: "price_1Pv8nFJwMgFSZnqLe1jChB26",
    features: ["Physical limited-edition \"OSS Supporter\" lapel pin", "Unique sticker collection",],
    name: "Supporter",
    price: 1000
  },
  {
    id: "price_1Pv8nyJwMgFSZnqLzgPlEfIl",
    features: ["Physical limited-edition \"OSS Supporter\" lapel pin", "Unique sticker collection", "Exclusive Discord community access"],
    name: "Sustainer",
    price: 2000
  },
];

const PRODUCTION_STRIPE_SUBSCRIPTIONS = [
  {
    id: "price_1QAh17JwMgFSZnqLm4I4uuKg",
    features: ["Unique sticker collection"],
    name: "Contributor",
    price: 500
  },
  {
    id: "price_1QAh12JwMgFSZnqLKQrxI8Vh",
    features: ["Physical limited-edition \"OSS Supporter\" lapel pin", "Unique sticker collection",],
    name: "Supporter",
    price: 1000
  },
  {
    id: "price_1QAh0uJwMgFSZnqLUeEbnH4W",
    features: ["Physical limited-edition \"OSS Supporter\" lapel pin", "Unique sticker collection", "Exclusive Discord community access"],
    name: "Sustainer",
    price: 2000
  },
];

export const STRIPE_SUBSCRIPTIONS = process.env.REACT_APP_LOCAL === "true" ? TEST_STRIPE_SUBSCRIPTIONS : PRODUCTION_STRIPE_SUBSCRIPTIONS;


export const WITHDRAWAL_THRESHOLD = 100;

const API_URL = process.env.REACT_APP_LOCAL === "true" ? 'http://localhost:8080' : 'https://a.gitgains.org';

export {API_URL};