// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth, connectAuthEmulator} from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAbCIGVNgrZkZBKROCA99fE0Vm2l2UX3z4",
  authDomain: "app.gitgains.org",
  projectId: "open-sourcify-9b6e8",
  storageBucket: "open-sourcify-9b6e8.appspot.com",
  messagingSenderId: "1019152056920",
  appId: "1:1019152056920:web:bb7a73452d4c4f50d7c02d"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const functions = getFunctions(app);
export const getPackageUsageStats = httpsCallable(functions, "package_usage_stats");
export const generateToken = httpsCallable(functions, "generate_proxy_token");
export const getCurrentBalance = httpsCallable(functions, "get_current_balance");

export const isGithubAuthenticated = httpsCallable(functions, "is_github_authenticated");
export const setGithubToken = httpsCallable(functions, "set_github_token");
export const getPublicRepos = httpsCallable(functions, "get_public_repos");

export const createStripeSession = httpsCallable(functions, "create_stripe_session");
export const getSubscriptions = httpsCallable(functions, "get_subscriptions");
export const getCurrentSubscription = httpsCallable(functions, "get_current_subscription");
export const cancelCurrentSubscription = httpsCallable(functions, "cancel_current_subscription");

if (process.env.REACT_APP_LOCAL === "true") {
  connectAuthEmulator(auth, 'http://localhost:9120');
  connectFunctionsEmulator(functions, 'localhost', 9121);
}

export default auth;
