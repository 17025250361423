import {Fragment, useEffect, useState} from "react";
import {Button, Card, Grid, styled, Typography, useTheme, CircularProgress, Box} from "@mui/material";
import auth, {getCurrentBalance} from "../../firebase";
import Subscription from "../../components/custom/Subscription";
import {WITHDRAWAL_THRESHOLD} from "../../constants";
import {useHttp} from "../../hooks/use-http";

// STYLED COMPONENTS
const ContentBox = styled("div")(({theme}) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: {margin: "16px"}
}));

const Title = styled("span")(() => ({
  fontSize: "1rem",
  fontWeight: "500",
  marginRight: ".5rem",
  textTransform: "capitalize"
}));

export default function Profile() {
  const {palette} = useTheme();
  const [balance, setBalance] = useState(0);
  const {request, loading} = useHttp();

  const dollars = Math.floor(balance / 100);
  const cents = balance % 100;

  const getBalance = async () => {
    try {
      let res = await request(`/user/balance`, 'GET');
      if (res) {
        setBalance(res['balance']);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <ContentBox className="profile">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <Card sx={{px: 3, py: 2, mb: 3}}>
              <Subscription/>
            </Card>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Card sx={{px: 3, py: 2, mb: 3}}>
              <Title>Your profile</Title>
              {auth.currentUser ? (
                <div>
                  <p>Name: {auth.currentUser.displayName}</p>
                  <p>Email: {auth.currentUser.email}</p>
                </div>
              ) : (
                <p>Loading user information...</p>
              )}
            </Card>
            <Card sx={{px: 3, py: 2, mb: 3}}>
              <Title>Your Balance</Title>
              <div>
                <p>Balance: <strong>${dollars}.{cents < 10 ? '0' : ''}{cents}</strong></p>
                <Typography variant="body2" color="textSecondary">
                  You can withdraw your money once your balance reaches ${WITHDRAWAL_THRESHOLD}.<br/>
                  Authenticate with Github to start earning money on your public repositories.
                </Typography>
              </div>
            </Card>
          </Grid>
        </Grid>
      </ContentBox>
    </Fragment>
  );
}