import axios from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { API_URL } from "../constants";
import auth from "../firebase";
import useAuth from "./useAuth";

export const useHttp = (externalAPI) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { isAuthenticated } = useAuth();
  const [isAuthReady, setIsAuthReady] = useState(false);

  const base = !externalAPI ? API_URL : externalAPI;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setIsAuthReady(true);
    });
    return () => unsubscribe();
  }, []);

  const request = useCallback(async (url, method = 'GET', body = null, headers = {}, isFile = false) => {
    if (!isAuthReady) {
      await new Promise(resolve => {
        const unsubscribe = auth.onAuthStateChanged(user => {
          setIsAuthReady(true);
          resolve();
          unsubscribe();
        });
      });
    }

    setLoading(true);
    try {
      if (body) {
        body = JSON.stringify(body);
        headers['Content-Type'] = 'application/json';
      }

      if (isAuthenticated) {
        headers['Authorization'] =`Bearer ${await auth.currentUser.getIdToken()}`;
      }
      const requestOptions = {
        method,
        url: base + url,
        data: body,
        headers
      }

      if (isFile) {
        requestOptions.responseType = 'arraybuffer';
      }

      const res = await axios(requestOptions);

      const data = await res?.data;

      if (res.status !== 200) {
        throw new Error(data.message || 'Something went wrong');
      }

      setLoading(false);

      return data;
    } catch (e) {
      setLoading(false);
      setError(e.message);
      throw e;
    }
  }, [isAuthReady, isAuthenticated]);

  const clearError = useCallback(() => setError(null), []);

  return {
    error,
    loading,
    request,
    clearError
  };
}