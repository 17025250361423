import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";
import GithubCode from "./views/sessions/GithubCode";
import Profile from "./views/profile/Profile";
import FAQ from "./views/faq/FAQ";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const Login = Loadable(lazy(() => import("app/views/sessions/Login")));
// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...materialRoutes,
      // dashboard route
      { path: "/dashboard", element: <Analytics />, auth: authRoles.user },
      {path: "/profile", element: <Profile/>, auth: authRoles.user},
      {path: "/faq", element: <FAQ/>, auth: authRoles.user},
      // e-chart route
      { path: "/charts/echarts", element: <AppEchart />, auth: authRoles.user}
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },
  { path: "/login", element: <Login /> },
  {path: "/github", element: <GithubCode/>},

  // stripe routes
  {path: "/subscription/cancel", element: <h1>Subscription Cancelled</h1>},
  {path: "/subscription/success", element: <h1>Subscription Success</h1>},

  { path: "/", element: <Navigate to="/dashboard" /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
